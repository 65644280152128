.formatted-desc table,
.formatted-desc th,
.formatted-desc td {
  border: 1px solid #cccccc !important;
}

.formatted-desc table {
  width: 100%;
}

.formatted-desc th {
  background-color: #e6e6e6;
}

/* * {
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  'Noto Naskh Arabic', serif;
} */
:root {
  --menu-font-family: "Quicksand", sans-serif;
  --menu-direction: ltr;
}

* {
  font-family: var(--menu-font-family);
  direction: var(--menu-direction);
  font-size: 0.9rem;
}

a {
  text-decoration: none !important;
}

body {
  background: #ddd !important;
}

.maplabel {
  font-weight: bold;
  font-size: 0.75rem !important;
}

.fa-file-pdf.file-thumbnail {
  color: tomato;
}

.fa-file-word.file-thumbnail {
  color: #0d6efd;
}

.fa-file-excel.file-thumbnail {
  color: #198754;
}

footer {
  background-color: #5c666c;
  color: #fff;
}

footer a {
  color: #fff;
  text-decoration: underline;
}

.header {
  background: none;
}

.header,
.header a {
  color: #161e2c;
}

.sidebar-header {
  color: #fff;
}

.btn-floating {
  border-radius: 50% !important;
}

.field-label {
  font-size: smaller;
}

.field-data {
  border-bottom: 1px solid #ccc;
}

.related-list-table a {
  text-decoration: none;
}

.btn-primary,
.btn-info {
  background-color: #6c757d !important;
  color: #fff !important;
  border-color: #6c757d !important;
}

.login-form {
  background-color: #fff;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  margin-top: 5rem;
}

.login-form i {
  color: #f7981c;
}

.login-section {
  border-left: 1px solid #ccc;
}

.login-form img {
  width: 100%;
}

.login-form .vr {
  border-right: 1px solid #ccc;
}

.data-table a {
  text-decoration: none;
}

.data-table th {
  background-color: #56404fbf !important;
  color: #fff !important;
  border: 1px solid #56404fbf;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
}

.data-table {
  border: 1px solid #56404fbf;
  border-radius: 5px;
}

.view-form label {
  font-size: smaller;
  display: block;
  text-transform: uppercase;
  margin-top: 1rem;
}

.view-form-header {
  background-color: #56404fbf;
  color: #fff;
  padding: 0.5rem;
  border-radius: 5px 5px 0 0;
}

.form-detail,
.form-edit {
  border: 1px solid #ccc;
  padding-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.75);
}

.view-form span {
  display: block;
  border-bottom: 1px solid #ccc;
}

.fl {
  padding-left: 0px !important;
}

.fr {
  padding-right: 0px !important;
}

.data-table {
  font-size: smaller;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input[type="number"].no-spinner::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.myTable {
  width: 800px;
}

.fc-event {
  cursor: pointer;
}

table.fc-col-header {
  background-color: #161e2c;
}

table.fc-col-header a {
  color: #fff !important;
}

th.fc-col-header-cell {
  padding: 0.5rem 0 0.5rem 0 !important;
  /*border: 1px solid #161e2c !important;*/
}

.btn-outline-primary {
  border: 1px solid !important;
  color: #333 !important;
}

.btn-outline-primary:hover {
  background-color: #56404fbf !important;
  color: #fff !important;
}

.btn-custom {
  border: 1px solid #1c3df7 !important;
  color: #fff !important;
  background: #1c3df7 !important;
}

/*
 * Progressbar
 */

.bar {
  --d: 1rem;
  /* arrow depth */
  --gap: 0.3rem;
  /* arrow thickness, gap */

  display: flex;
  margin-right: var(--d);
}

.bar-step {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.6rem var(--d);
  margin-right: calc(var(--d) * -1 + var(--gap));
  background: #d3d7dd;
  color: #23468c;
  clip-path: polygon(0% 0%,
      calc(100% - var(--d)) 0%,
      100% 50%,
      calc(100% - var(--d)) 100%,
      0% 100%,
      var(--d) 50%);
}

.bar-step:first-child {
  clip-path: polygon(0% 0%,
      calc(100% - var(--d)) 0%,
      100% 50%,
      calc(100% - var(--d)) 100%,
      0% 100%);
}

.bar-step.active {
  background: #23468c;
  color: #fff;
}

.bar-step.completed {
  background: rgb(79, 167, 86);
  color: #fff;
}

.ibs-edit-form {
  background-color: #fff;
  margin-bottom: 1rem;
}

.card-header {
  background-color: #56404fbf !important;
  color: #fff !important;
}

.nav-link[aria-selected="false"] {
  color: #fff !important;
  /* border-bottom: 1.7px solid white; */
}

.nav-link-category:hover {
  color: #333 !important;
  border-bottom: 3.7px solid rgb(84, 49, 102) !important;
  /*red !important;*/
}

.nav-link {
  color: #fff;
  margin-left: 10px;
}

.navbar-nav .nav-link.active-link {
  border-bottom: 3.7px solid rgb(84, 49, 102) !important;
  /*red !important;*/
}

.section-header {
  background-color: #56404fbf;
  color: #fff;
  padding: 0.5rem;
  margin-top: 1rem !important;
  text-transform: uppercase;
}

.form-select[required] {
  border-left: 4px solid red;
}

.Select[required] {
  border-left: 4px solid red;
}

input.form-control[required] {
  border-left: 4px solid red;
}

input.form-control[required] {
  border-left: 4px solid red;
}

textarea.form-control[required] {
  border-left: 4px solid red;
}

.form-select[required] {
  border-left: 4px solid red;
}

.custom-select {
  border-left: 4px solid red;
}

._3ybTi {
  display: inline;
}

div#react-select-2-listbox {
  white-space: pre-wrap;
}

.flex-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* pagination start */
.flex-container>div {
  padding-top: 10px;
}

.paginate-ctn {
  display: flex;
}

.round-effect {
  color: #dd4124;
  cursor: pointer;
  /*   border:1px solid red; */

  font-size: 16px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 9px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  text-decoration: none;
}

.round-effect:hover {
  text-decoration: none;
  background: #dd4124;
  color: #fff;
}

.round-effect:hover a {
  text-decoration: none;
  color: #fff;
}

.pageactive {
  background: #dd4124;
  color: #fff;
}

/* a:link {
  text-decoration: none;
  color:#DD4124;
} */

/* End */

.card-img-container {
  min-height: 400px;
  max-height: 400px;
  background-size: cover;
  /* background-position: center; */
  border-bottom: 1px solid #dee2e6;
}

.searchinput,
.searchinput:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.product-card {
  box-shadow: 1px 3px 4px 0px #80808078 !important;
  border: none !important;
}

.bg-nav {
  background-color: #f8f9fa !important;
  /* border-bottom: 1.7px solid white; */
}

.bg-nav .nav-link {
  font-size: 1.3rem !important;
  border-bottom: 1.7px solid #f8f9fa;
}

.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

/**** Rating ****/

.rating>input {
  display: none;
}

.rating>label {
  position: relative;
  width: 1em;
  font-size: 6vw;
  color: #ffd600;
  cursor: pointer;
}

.rating>label::before {
  content: "\2605";
  position: absolute;
  opacity: 0;
}

.rating>label:hover:before,
.rating>label:hover~label:before {
  opacity: 1 !important;
}

.rating>input:checked~label:before {
  opacity: 1;
}

.rating:hover>input:checked~label:before {
  opacity: 0.4;
}

.brand-name {
  font-size: 1.8rem;
  font-weight: 300;
  color: rgb(79, 50, 103);
}

.brand-name span {
  font-size: 2.5rem;
  font-weight: bold;
}

/**** Rating ****/

@media only screen and (max-width: 600px) {
  .productgridsearchbox {
    height: 3rem;
    font-size: 16px !important;
  }

  /* yamini 21-8-2023  */
  .menuwide-card-img-container {
    min-height: 100px;
    max-height: 200px;
    background-size: cover;
    background-position: center;
    border-bottom: 1px solid #dee2e6;
    border-radius: 50%;
    width: 100px;
  }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .productgridsearchbox {
    height: 3rem;
    font-size: 16px !important;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .social-icon {
    border-top: none !important;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .social-icon {
    border-top: none !important;
  }
}

.carousel-control-next,
.carousel-control-prev {
  opacity: unset !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 3rem !important;
  height: 3rem !important;
}

.carousel-control-prev-icon {
  background-image: url("../public/prevIcon.svg") !important;
  /* background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%221em%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20d%3D%22M512%20256A256%20256%200%201%200%200%20256a256%20256%200%201%200%20512%200zM271%20135c9.4-9.4%2024.6-9.4%2033.9%200s9.4%2024.6%200%2033.9l-87%2087%2087%2087c9.4%209.4%209.4%2024.6%200%2033.9s-24.6%209.4-33.9%200L167%20273c-9.4-9.4-9.4-24.6%200-33.9L271%20135z%22%2F%3E%3C%2Fsvg%3E") !important; */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%23f19e3e%7D%3C/style%3E%3Cpath d='M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM271 135c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-87 87 87 87c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L167 273c-9.4-9.4-9.4-24.6 0-33.9L271 135z'/%3E%3C/svg%3E")  !important; */
}

.carousel-control-next-icon {
  background-image: url("../public/nextIcon.svg") !important;
  /* background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%221em%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20d%3D%22M0%20256a256%20256%200%201%200%20512%200A256%20256%200%201%200%200%20256zM241%20377c-9.4%209.4-24.6%209.4-33.9%200s-9.4-24.6%200-33.9l87-87-87-87c-9.4-9.4-9.4-24.6%200-33.9s24.6-9.4%2033.9%200L345%20239c9.4%209.4%209.4%2024.6%200%2033.9L241%20377z%22%2F%3E%3C%2Fsvg%3E") !important; */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%23f19e3e%7D%3C/style%3E%3Cpath d='M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z'/%3E%3C/svg%3E") !important; */
}

.custom-carousel .carousel-control-next {
  margin-right: 5px;
}

.custom-carousel .carousel-indicators {
  display: none;
}

.scrolltopbutton {
  /* display: none; */
  position: fixed !important;
  bottom: 20px;
  right: 10px;
  z-index: 99;
  /* font-size: 18px; */
  border: none;
  outline: none;
  /* background-color: #f19e3e; */
  color: white;
  cursor: pointer;
  padding: 14px 20px;
  border-radius: 100%;
}

.scrolltopbutton {
  font-size: 18px;
  vertical-align: middle;
}

.empty-input {
  border: 1px solid red !important;
}

.card-img-container {
  background-position: center;
}

.menuwide-card-img-container {
  min-height: 150px;
  max-height: 200px;
  background-size: cover;
  /* background-position: center; */
  border-bottom: 1px solid #dee2e6;
  border-radius: 50%;
  width: 155px;
}


.support li {
  margin-top: 1rem;
}

.productDetil {
  line-height: 12px;
}

/* Start to updated*/
.spndetails {
  /* font-size: 1.3rem; */
  color: "rgb(79, 50, 103)";
  max-height: 0cqh;
}

/* @media only screen and (min-device-width: 768px) {
  .productRating{
    padding-left: 160px;
  }
} */

.card-img-slider {
  /* min-height: 400px;
  max-height: 800px;
  background-size: cover; */
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  /* height: 100vh; */
  height: 500px;
  background-position: top;
}

@media (min-width: 320px) and (max-width: 480px) {
  .menuwide-card-img-container {
    width: fit-content;
    min-width: 100px;
    min-height: 100px;
  }

  .card-img-slider {
    height: 29vh !important;
  }

  

  /*End  yamini 28-08-2023 */
}

@media (min-width: 768px) and (max-width: 1024px) {
  .card-img-slider {
    height: 50vh !important;
  }
}

.seeMore {
  cursor: pointer;
  font-size: 1.3rem;
}

.div-content {
  border-bottom: 1px solid #80808057;
  min-height: 2rem;
}

/* yamini 21-08-2023 start*/

.detail-back {
  font-size: medium;
  color: black;
}

.card-video-container {
  max-height: 400px;
  background-size: cover;
  /* border-bottom: 1px solid #dee2e6; */
  height: 250px;
}

.thumbnail-container {
  max-width: 100%;
  /* Ensure the container doesn't exceed the available width */
  overflow-x: auto;
  /* Add horizontal scroll if thumbnails overflow */
}

.thumbnail-container img {
  max-width: 80px;
  /* Adjust the value to your desired size */
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;
  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;
  /* icon size */
  background-size: 14px;
}

.product-descrption {
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* yamini 21-08-2023 end*/


.ribbon-2 {
  --f: 10px;
  /* control the folded part*/
  --r: 12px;
  /* control the ribbon shape */
  --t: 10px;
  /* the top offset */
  z-index: 10;
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 10px 10px var(--f) calc(10px + var(--r));
  padding-bottom: 1.2rem;
  clip-path:
    polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  /* background: #309526; */
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  color: #fff;
  font-weight: bold;
}

.ribbon-2-menu-wide {
  --f: 10px;
  /* control the folded part*/
  --r: 12px;
  /* control the ribbon shape */
  --t: 10px;
  /* the top offset */
  z-index: 10;
  /* position: absolute; */
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 10px 10px var(--f) calc(10px + var(--r));
  padding-bottom: 1.2rem;
  clip-path:
    polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  /* background: #309526; */
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  color: #fff;
  font-weight: bold;
  /* overflow: inherit !important; */
}

/* Yamini 28-08-2023 Start*/
/* More Menus Item CSS */
.custom-nav-dropdown-item .nav-link-category a {
  font-size: 1.1rem !important;
}

.custom-nav-dropdown-item {
  border-bottom: none !important;
  margin-left: 0px;
}

.custom-nav-dropdown-item a:hover {
  border-bottom: none !important;
  background-color: #f8f9fa;
  /* font-weight: 600; */
}

.custom-nav-dropdown-item a {
  color: #161e2c;

}

.dropdown {
  padding-right: 30px;

}

.custom-nav-dropdown-item a[aria-expanded="true"] {
  background-color: #f8f9fa;
}

 

/* Yamini 28-08-2023  End*/
.ribbon-2-detail {
  --f: 10px;
  /* control the folded part*/
  --r: 12px;
  /* control the ribbon shape */
  --t: 10px;
  /* the top offset */
  z-index: 10;
  /* position: absolute; */
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 10px 10px var(--f) calc(10px + var(--r));
  padding-bottom: 1.2rem;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%, calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)), var(--r) calc(50% - var(--f) / 2));
  /* background: #309526; */
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  color: #fff;
  font-weight: bold;
  /* overflow: inherit !important; */
}
